exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-puppies-js": () => import("./../../../src/pages/all-puppies.js" /* webpackChunkName: "component---src-pages-all-puppies-js" */),
  "component---src-pages-blog-mongodb-puppysrus-learningcenter-slug-js": () => import("./../../../src/pages/blog/{MongodbPuppysrusLearningcenter.slug}.js" /* webpackChunkName: "component---src-pages-blog-mongodb-puppysrus-learningcenter-slug-js" */),
  "component---src-pages-breeds-js": () => import("./../../../src/pages/breeds/[...].js" /* webpackChunkName: "component---src-pages-breeds-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-financing-js": () => import("./../../../src/pages/financing.js" /* webpackChunkName: "component---src-pages-financing-js" */),
  "component---src-pages-for-sale-js": () => import("./../../../src/pages/for-sale/[...].js" /* webpackChunkName: "component---src-pages-for-sale-js" */),
  "component---src-pages-happyfamilies-js": () => import("./../../../src/pages/happyfamilies.js" /* webpackChunkName: "component---src-pages-happyfamilies-js" */),
  "component---src-pages-health-guarantee-js": () => import("./../../../src/pages/health-guarantee.js" /* webpackChunkName: "component---src-pages-health-guarantee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-center-js": () => import("./../../../src/pages/learning-center.js" /* webpackChunkName: "component---src-pages-learning-center-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-pup-js": () => import("./../../../src/pages/pup/[...].js" /* webpackChunkName: "component---src-pages-pup-js" */),
  "component---src-pages-puppies-js": () => import("./../../../src/pages/puppies.js" /* webpackChunkName: "component---src-pages-puppies-js" */),
  "component---src-pages-purchase-js": () => import("./../../../src/pages/purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */),
  "component---src-pages-share-your-puppys-r-us-experience-js": () => import("./../../../src/pages/share-your-puppys-r-us-experience.js" /* webpackChunkName: "component---src-pages-share-your-puppys-r-us-experience-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-templates-dynamic-breed-js": () => import("./../../../src/templates/dynamicBreed.js" /* webpackChunkName: "component---src-templates-dynamic-breed-js" */)
}

